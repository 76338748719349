import { ethers } from "ethers";
import abi from "../contracts/abi.json";
import { useAccount, useSigner } from "wagmi";
import { useState } from "react";
import { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortAdd } from "../utils";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Main = () => {
  const tokenAdd = "0xA4bF9CeE4Ca4A870b922dA7c76848c3Eb222d942";
  const keyAdd = "0x3b8fA9f314cD6a9837826DAdCc6E13d7DEF115Df";

  const [claimStarted, setClaimStat] = useState(false);
  const [available, setavailable] = useState(0);
  const [copied, setCopied] = useState(false);

  const prov = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/eth");
  const claimView = new ethers.Contract(keyAdd, abi, prov);

  const { data: signer } = useSigner();
  const { address, isConnected } = useAccount();

  const claim = async () => {
    if (!isConnected) return;
    const claimContract = new ethers.Contract(keyAdd, abi, signer);

    try {
      const getTok = await claimContract.claim();
      await getTok.wait();

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tokens Claimed Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      getInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const changeCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getInfo = async () => {
    try {
      const claimPhase = await claimView.claimingStarted();
      setClaimStat(claimPhase);

      if (!isConnected) return;
      const data = await claimView.claimableTokens(address);
      const amou = ethers.utils.formatUnits(data, 18);
      setavailable(Number(amou).toFixed(0));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, [isConnected]);

  return (
    <section className="w-full flex justify-center">
      <div className="w-full px-4 max-w-screen-xl flex flex-col items-center justify-center">
        <span className="text-[#fff] mt-32 md:mt-56 flex gap-4 my-5 font-bold text-[30px]">
          Claim Status:
          <div>
            {claimStarted ? (
              <span className="bg-green-300 text-black p-1 rounded-lg">
                Live
              </span>
            ) : (
              <span className="bg-red-500 p-1 rounded-lg">Offline</span>
            )}
          </div>
        </span>

        <p className="bg-[#ededed] py-5 w-[350px] md:w-[600px] text-[30px] md:text-[40px] text-center rounded-md max-w-lg text-black">
          {isConnected ? (
            <span className="text-[25px] flex flex-col gap-4 leading-[22px]">
              <span> {available}</span>
              $PARADOX tokens available for claim
            </span>
          ) : (
            "Paradox"
          )}
        </p>

        <div className="flex my-2">
          <CopyToClipboard text={tokenAdd}>
            <button
              onClick={changeCopy}
              className="bg-[#ffffff] ease-out duration-200 hover:bg-[#ececec] flex items-center gap-2 font-bold text-[#000000] text-[14px] py-[6px] px-[40px] rounded-[10px]"
            >
              <img
                src="https://www.scatter.art/_next/image?url=%2Fimages%2Feth-purple.png&w=32&q=75"
                alt=""
                className="w-6"
              />{" "}
              {copied ? "Address Copied!" : shortAdd(tokenAdd)}
            </button>
          </CopyToClipboard>
          <button className="rounded-full"></button>
        </div>
        <div className="flex gap-2 mt-4">
          <button
            onClick={claim}
            className="bg-[#000000] border-white border-2 text-[#fff] px-4 p-2 rounded"
          >
            Claim Tokens
          </button>
        </div>
      </div>
    </section>
  );
};

export default Main;
