import { useAccount, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { shortAdd } from "../../utils";
import logo from "../../logo.svg";

const Header = () => {
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();

  const { address, isConnected } = useAccount();

  const connectWallet = () => {
    try {
      switchNetwork?.(1);
      open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header className="w-full flex justify-center bg-black ">
      <div className="max-w-screen-2xl shadow-2xl w-full flex justify-between p-5">
        <h1 className="flex items-center gap-4">
          <img
            src={logo}
            alt=""
            className="h-10"
          />
          <span className="text-2xl text-white hidden md:flex">Paradox</span>
        </h1>

        <div className="flex gap-10">
          {isConnected ? (
            <button className="bg-[#ffffff] text-[#000] font-bold px-4 p-2 rounded">
              {shortAdd(address)}
            </button>
          ) : (
            <button
              onClick={connectWallet}
              className="bg-[#ffffff] text-[#000] font-bold px-4 p-2  rounded"
            >
              Connect Wallet
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
