import React from "react";
import Header from "./components/Header";
// ES6 Modules or TypeScript
import Swal from "sweetalert2";

import { BrowserRouter } from "react-router-dom";
import Main from "./Pages/Main";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Main />
    </BrowserRouter>
  );
}

export default App;
